import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Input, Switch } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import parse from "html-react-parser";
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import blueTriangleWorkflow from '@iso/assets/images/blueTriangleWorkflow.svg';
import awsLogo from '../../../assets/images/aws.svg';
import { formatErrors, marketplaceStatuses, timeSince, workflowContents } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import dragIcon from '@iso/assets/images/drag-icon.svg';
import deleteIcon from '@iso/assets/images/deleteIcon.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import ScrollableFeed from 'react-scrollable-feed';
import CodeViewGeneral from '../../Clusters/Task/CodeViewGeneral';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';

const noDataTitle = {
    color: "#A6A6A6",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
};

const noDataSubTitle = {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "5px"
};

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let innerSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
}

const blueTriangleStyle = {
    position: "absolute",
    right: "2.5%",
    top: "40%",
}

const widgetStyle = {
    margin: "40px 20px",
    width: "inherit",
    marginTop: "124px"      // 84px + 40px
};

const inputStyle = {
    height: "44px"
}

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

let failureButtonsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 9px",
    padding: "10px 57px",
    borderRadius: "8px",
    border: "solid 1px #132f44",
}


export default ({
    data,
    openDrawer,
    columnId,
    ind,
    type,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, workflowNodeData, setWorkflowNodeData, workflowEdgesData, setWorkflowEdgesData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const [toolsData, setToolsData] = useState(workflowNodeData);
    const [toolSelected, setToolSelected] = useState(0);
    const [envDropdown, setEnvDropdown] = useState([]);
    const [envList, setEnvList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [script, setScript] = useState("");
    const [error, setError] = useState(false);
    // const [allTools, setAllTools] = useState([
    //     // { name: "Environment", img: "", description: "Click to configure", onTop: true, order: 0 },
    //     { toolName: "Secret Detection", img: "", description: "Click to configure", secretDetectionTrigger: false, onTop: false, order: 0 },
    //     { toolName: "Trivy", img: "", description: "Click to configure", maxCriticalVulnerability: "", maxHighVulnerability: "", onTop: false, order: 1 },
    // ]);


    useEffect(() => {
        api.get(process.env.REACT_APP_API_URI + '/api/project_env/env/create/')
            .then((res) => {
                let data = res.data;
                console.log("env list :", data);
                if (data.status) {
                    let myEnvs = [];

                    let arr = data.data.sort((a, b) => (a.name.localeCompare(b.name)));

                    let listOfEnvs = []
                    currentWorkflowData.deployConfig.tools.map((item, idx) => {
                        if(idx != ind){
                            listOfEnvs.push(item.envId);
                        }
                    });

                    if(currentWorkflowData?.deployConfig?.config?.env_unique_id){
                        listOfEnvs.push(currentWorkflowData.deployConfig.config.env_unique_id);
                    }
                    console.log("listOfEnvs :", listOfEnvs);

                    arr.map((temp, idx) => {
                        if ([0, 1, 2, 3, 8].includes(temp.phase) && !listOfEnvs.includes(temp.env_unique_id)) {
                            myEnvs.push(<Option key={idx} name="env" data-id={temp.env_unique_id} value={temp.name}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div>
                                        <img src={temp.account_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="icon" />
                                        <span>{temp.name}</span>
                                    </div>
                                    {/* <span>{temp.cluster_type}</span> */}
                                </div>
                            </Option>)
                        }
                    });

                    setEnvDropdown(myEnvs);
                    setEnvList(data.data);
                    setLoading(false);
                }
                else if (!data.status) {
                    let err = formatErrors(data.error_details);
                    console.log("err in project_env/create :", err);
                    setLoading(false);
                }
            }).catch((err) => {
                console.log("err :", err);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        console.log("workflowData in tools edit :", workflowData);
        console.log("workflowNodeData in tools edit :", workflowNodeData);
        console.log("workflowEdgesData in tools edit :", workflowEdgesData);
        console.log("data in tools edit :", data);
        console.log("currentWorkflowData in tools edit :", currentWorkflowData);

        if(data.label === "Secret Detection"){
            setToolSelected(currentWorkflowData.sourceConfig.tools[0]);
        }
        else if(data.label === "pre_build_script"){
            // setScript(currentWorkflowData.sourceConfig.customScriptConfig.config.script);
            let str = currentWorkflowData.sourceConfig.customScriptConfig.config.script;
            try {
                let newStr = JSON.parse(str);
                if(newStr.startsWith('\"') && newStr.endsWith('\"')){
                    newStr = newStr.replace(/\\n/g, "\n").replace(/\\"/g, '"');
                    newStr = newStr.slice(1, -1);
                }
                console.log("New parsed script :", newStr);
                setScript(newStr);
            } catch (err) {
                console.log("err :", err);
                console.log("using script as is...");
                setScript(currentWorkflowData.sourceConfig.customScriptConfig.config.script);
            }
            setError(false);
        }
        else if(data.label === "Trivy"){
            setToolSelected(currentWorkflowData.buildConfig.tools[0]);
            form.setFieldsValue({
                ...currentWorkflowData.buildConfig.tools[0]
            })
        }
        else if(data.label === "post_build_script"){
            // setScript(currentWorkflowData.buildConfig.customScriptConfig.config.script);
            let str = currentWorkflowData.buildConfig.customScriptConfig.config.script;
            try {
                let newStr = JSON.parse(str);
                if(newStr.startsWith('\"') && newStr.endsWith('\"')){
                    newStr = newStr.replace(/\\n/g, "\n").replace(/\\"/g, '"');
                    newStr = newStr.slice(1, -1);
                }
                console.log("New parsed script :", newStr);
                setScript(newStr);
            } catch (err) {
                console.log("err :", err);
                console.log("using script as is...");
                setScript(currentWorkflowData.buildConfig.customScriptConfig.config.script);
            }
            setError(false);
        }
        else if(data.label === "Deploy"){
            setToolSelected(currentWorkflowData.deployConfig.tools[ind]);
            form.setFieldsValue({
                ...currentWorkflowData.deployConfig.tools[ind]
            })
        }
    }, []);


    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }

    const handleChecked = (value) => {
        // toolsData?.preBuildToolsData?.[toolSelected]?.secretDetectionTrigger
        console.log("inside handleChecked value :", value);
        // set value
        // allTools[toolSelected].secretDetectionTrigger = value;
        // console.log("inside handleChecked allTools :", allTools);

        // let tempSelectedTools = allTools.filter((temp) => temp.onTop);
        // console.log("inside handleChecked tempSelectedTools :", tempSelectedTools);

        // setToolsData((prev) => ({
        //     ...prev,
        //     preBuildToolsData: tempSelectedTools,
        // }));
        // setAllTools([...allTools]);
        if(data.label === "Secret Detection"){
            setToolSelected({
                ...toolSelected,
                secretDetectionTrigger: value,
            });
        }
    }

    const handleSaveDetails = async(e) => {
        console.log("currentWorkflowData inside handleSaveDetails :", currentWorkflowData);
        console.log("toolSelected inside handleSaveDetails :", toolSelected);
        console.log("data inside handleSaveDetails :", data);
        // // if()
        // let tempSelectedTools = allTools.filter((temp) => temp.onTop);

        // console.log("tempSelectedTools in handleSaveDetails :", tempSelectedTools);

        // setWorkflowData((prevData) => ({
        //     ...prevData,
        //     postBuildToolsData: tempSelectedTools,
        // }));

        if(data.label === "Secret Detection"){
            if(toolSelected.secretDetectionTrigger){
                currentWorkflowData.sourceConfig.tools[0].secretDetectionTrigger = true;
            }
            else{
                // remove secret detection from source
                currentWorkflowData.sourceConfig.tools = [];
                setWorkflowData((prevData) => ({
                    ...prevData,
                    preBuildToolsData: [],
                }));
            }

            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));

            handelClick();
        }
        else if(data.label === "pre_build_script"){
            if(script){
                currentWorkflowData.sourceConfig.customScriptConfig.config.script = script;
            }
            else if(script == ""){
                // remove secret detection from source
                currentWorkflowData.sourceConfig.customScriptConfig = {};
            }

            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));

            handelClick();
        }
        else if(data.label === "Trivy"){
            try {
                const values = await form.validateFields();
                console.log('Success:', values);

                currentWorkflowData.buildConfig.tools[0].maxCriticalVulnerability = toolSelected.maxCriticalVulnerability;
                currentWorkflowData.buildConfig.tools[0].maxHighVulnerability = toolSelected.maxHighVulnerability;
                setCurrentWorkflowData({...currentWorkflowData});
                localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
                handelClick();
            }
            catch (err) {
                console.log("Found error :", err);
            }
        }
        else if(data.label === "post_build_script"){
            if(script){
                currentWorkflowData.buildConfig.customScriptConfig.config.script = script;
            }
            else if(script == ""){
                // remove secret detection from source
                currentWorkflowData.buildConfig.customScriptConfig = {};
            }

            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));

            handelClick();
        }
        else if(data.label === "Deploy"){
            try {
                const values = await form.validateFields();
                console.log('Success:', values);

                currentWorkflowData.deployConfig.tools[ind].env = toolSelected.env;
                currentWorkflowData.deployConfig.tools[ind].envId = toolSelected.envId;

                console.log("currentWorkflowData in toolsEdit page :", currentWorkflowData);
                setCurrentWorkflowData({...currentWorkflowData});
                localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
                handelClick();
            }
            catch (err) {
                console.log("Found error :", err);
            }
        }
    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelClick();
    }

    const handelInputChange = (e) => {
        console.log("e.target.name handelInputChange :", e.target.name);
        console.log("e.target.value handelInputChange :", e.target.value);
        // if(data.label === "Secret Detection"){
        // }
        if(data.label === "Trivy"){
            setToolSelected({
                ...toolSelected,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        console.log("toolsData :", toolsData);

        if(data.label === "Deploy"){
            setToolSelected({
                ...toolSelected,
                [event.name]: value,
                envId: event["data-id"]
            });
            
            if (event.name === "env-redirect") {
                navigate(`/environments/create`);
                return;
            }
        }
    }

    const handleUnselectedTools = (e) => {
        // console.log("clicked handleUnselectedTools :", e.target);
        // console.log("clicked handleUnselectedTools e.target.id :", e.target.id);

        // if (e.target?.id) {
        //     allTools[e.target.id].onTop = true;
        //     setAllTools([...allTools]);
        // }
    }

    const handleSelectedTools = (e) => {
        // console.log("clicked handleSelectedTools :", e.target);
        // console.log("clicked handleSelectedTools e.target.id :", e.target.id);

        // if (e.target?.id) {
        //     allTools[e.target.id].onTop = false;
        //     setAllTools([...allTools]);
        // }
    }

    // const showSelectTool = (e) => {
    //     console.log("clicked showSelectTool :", e.target);
    //     console.log("clicked showSelectTool e.target.id :", e.target.id);

    //     if (e.target?.id) {
    //         setToolSelected(parseInt(e.target.id));
    //         // setAllTools([...allTools]);
    //     }
    // }

    // const handleDragTools = (e) => {
    //     console.log("clicked handleDragTools :", e.target);
    //     console.log("clicked handleDragTools e.target.id :", e.target.id);

    //     if (e.target?.id) {
    //         // setToolSelected(parseInt(e.target.id));
    //         // setAllTools([...allTools]);
    //     }
    // }



    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0", height: "100%" }}>
            <CreateTaskHeader buttonContent={["pre_build_script", "post_build_script"].includes(data.label) ? workflowContents[data.label] : data.label} onCancel={handelClick} />

            {/* <div style={{ marginTop: "30px", width: "100%" }} /> */}

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle }}>
                <Form
                    form={form}
                    initialValues={{ ...toolSelected }}
                    preserve={false}
                    name="dynamic_rule"
                    style={{
                        width: "100%",
                    }}
                >
                    {data.label === "Deploy" && <>
                        <Box
                            title={"Environment*"}
                            subtitle={"Provide select you environment."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0" }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Form.Item
                                    name="env"
                                    rules={[
                                        // {
                                        //     required: true,
                                        //     message: 'Please provide App Name.',
                                        // },
                                    ]}
                                >
                                    <Select
                                        value={toolSelected?.env}
                                        placeholder="Select Environment"
                                        onChange={handleSelectChange}
                                        style={{ width: "100%" }}
                                    >
                                        {envDropdown.length > 0 ? envDropdown :
                                            <Option key="0" name="env-redirect" data-alias="" type="integration" style={{ background: "white" }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                                    <img src={noDataImg} />
                                                    <span style={noDataTitle}>No Environments available.</span>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={noDataPlusIcon} />
                                                        <span style={noDataSubTitle}>Create one</span>
                                                    </div>
                                                </div>
                                            </Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </ContentHolder>
                        </Box>
                    </>}

                    {data.label === "Secret Detection" && <>
                        <Box
                            title={"Enable Secret Detection*"}
                            subtitle={"Check the box to enable Secret Detection."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                        >
                            <Switch style={toolSelected?.secretDetectionTrigger ? { backgroundColor: "#132f44" } : {}} checked={toolSelected?.secretDetectionTrigger} onChange={handleChecked} />
                        </Box>
                    </>}

                    {data.label === "Trivy" && <>
                        <Box
                            title={"Max critical vulnerability*"}
                            subtitle={"Mention your max critical vulnerability."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Form.Item
                                    name="maxCriticalVulnerability"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide max critical vulnerability.',
                                        },
                                    ]}
                                >
                                    <Input
                                        name="maxCriticalVulnerability"
                                        value={toolSelected?.maxCriticalVulnerability}
                                        onChange={handelInputChange}
                                        placeholder="2"
                                        style={inputStyle}
                                    // onBlur={getPublicBranches}
                                    />
                                </Form.Item>
                            </ContentHolder>
                        </Box>

                        <Box
                            title={"Max high vulnerability*"}
                            subtitle={"Mention your max high vulnerability."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                        >
                            <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                <Form.Item
                                    name="maxHighVulnerability"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide max high vulnerability.',
                                        },
                                    ]}
                                >
                                    <Input
                                        name="maxHighVulnerability"
                                        value={toolSelected?.maxHighVulnerability}
                                        onChange={handelInputChange}
                                        placeholder="5"
                                        style={inputStyle}
                                    // onBlur={getPublicBranches}
                                    />
                                </Form.Item>
                            </ContentHolder>
                        </Box>
                    </>}

                    {data.label === "pre_build_script" && <>
                        <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, width: "inherit", marginTop: "0", border: "0", padding: "0" }}>
                            <CodeViewGeneral data={script} setData={setScript} setError={setError} height="calc(100vh - 300px)" minHeight="370px" type="script" />
                            {/* {error && <p className="isoHelperText labels">Custom script can't be empty</p>} */}
                        </StickerWidgetWrapper>
                    </>}
                    
                    {data.label === "post_build_script" && <>
                        <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, width: "inherit", marginTop: "0", border: "0", padding: "0" }}>
                            <CodeViewGeneral data={script} setData={setScript} setError={setError} height="calc(100vh - 300px)" minHeight="370px" type="script" />
                            {error && <p className="isoHelperText labels">Custom script can't be empty</p>}
                        </StickerWidgetWrapper>
                    </>}
                </Form>
            </StickerWidgetWrapper>

            <CreateTaskFooter buttonContent={"Custom Script"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />

        </LayoutWrapper>
    );
};