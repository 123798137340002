import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import { Alert, Input, Spin, Tooltip } from 'antd';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select from '../../Deployments/Deployment.styles';
import {
  SelectOption as Option,
} from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { borderRadius } from '../../../library/helpers/style_utils';
import {
  PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import deleteIcon from '../../../assets/images/tagsDelete.svg';
import { Switch } from 'antd';
import downIcon from '../../../assets/images/downArrow.svg';
import upIcon from '../../../assets/images/upArrow.svg';
import api from '../../../api';
import { InfoCircleFilled } from '@ant-design/icons';
import { ConvertUTCToLocal, ConvertUTCToLocalDate, customFilterOption, customSortOptions } from '../../../context/helper';
import CustomInfoTooltip from '../../Dashboard/CustomComponents/CustomInfoTooltip';
import { clusterHelpChecks } from '../clusterHelpChecks';


const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
  serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

let innerTitleStyle = {
  color: "#1f1f1f",
  fontFamily: "Poppins",
  fontType: "medium",
  fontWeight: "500",
  fontSize: "14px"
};

let innerSubtitleStyle = {
  fontSize: "12px",
  color: "#9b9b9b",
  fontFamily: "Nunito Sans",
  fontWeight: "400",
  fontType: "regular",
}

let subContentStyle = {
  fontFamily: "Nunito Sans",
  fontSize: "13px",
  fontWeight: "300",
  // text-align: left,
  color: "#afafaf",
}

const inputStyle = {
  height: "44px"
}

export default function ClusterProd({
  // handleSubmit,
  data,
  setData,
  values,
  submitCount,
  onCancel,
  onDelete,
  openDrawer,
  onEditCancel,
  tabSelected,
  openAddOns
}) {

  const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, updateCluster, setUpdateCluster, setCurrentCluster, isUpdate, isOneClick, oneClickCluster, setOneClickCluster, clusterTabSelected, fetchingClusterPrerequisites, setFetchingClusterPrerequisites } = useContext(AppContext);

  const [myClusterData, setMyClusterData] = useState(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  console.log("Begin myclusterdata: ", myClusterData);
  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });
  const navigate = useNavigate();
  const [myVersions, setMyVersions] = useState([]);
  const [clusterLogRetentionOptions, setClusterLogRetentionOptions] = useState([]);
  const [vpcLogRetentionOptions, setVpcLogRetentionOptions] = useState([]);
  const [flowLogMaxOptions, setFlowLogMaxOptions] = useState([]);
  const [isAdvanced, setIsAdvanced] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  // useEffect(() => {
  //   console.log("Current Cluster useEffect in cluster prod: ", currentCluster);
  //   setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  // }, [currentCluster, updateCluster, oneClickCluster]);

  useEffect(() => {
    let arr = [];
    let clusterLog = [];
    let vpcLog = [];
    let flowLogMaxList = [];
    if (isUpdate) {
      let childVerInd = -2;
      updateCluster.prod.versionsAvailable.map((child, ind) => {
        if (child.version === updateCluster.selectedCluster.cluster_data.kubernetes_version) {
          childVerInd = ind;
          arr.push(<Option key={child.version} name="eks" value={child.version}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <span>{child.version}</span><span>current</span>
            </div>
            <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
            <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
          </Option>);
        }
        else {
          arr.push(<Option key={child.version} disabled={(childVerInd + 1) !== ind} name="eks" value={child.version}>
            <div>
              <p style={{}}>{child.version}</p>
              <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
              <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
            </div>
          </Option>);
        }
      });
    }
    else {
      let selectedCluster = isOneClick ? oneClickCluster : currentCluster;
      selectedCluster.prod.versionsAvailable.map((child, ind) => {
        arr.push(<Option key={child.version} name="eks" value={child.version}>
          <div>
            <p style={{}}>{child.version}</p>
            <p style={subContentStyle}>{`End of Extended Support : `}<b> {child.end_of_extended_support_time ? ConvertUTCToLocalDate(child.end_of_extended_support_time) : "N/A"}</b></p>
            <p style={subContentStyle}>{`End of Standard Support : `}<b> {child.end_of_standard_support_time ? ConvertUTCToLocalDate(child.end_of_standard_support_time) : "N/A"}</b></p>
          </div>
        </Option>);
      });
    }

    initialData.retentionPeriodInDays.map((child, ind) => {
      clusterLog.push(<Option key={child} name="clusterLogRetention" value={child}>{child}</Option>);
    });

    initialData.retentionPeriodInDays.map((child, ind) => {
      vpcLog.push(<Option key={child} name="logRetention" value={child}>{child}</Option>);
    });

    initialData.flowLogMaxInSec.map((child, ind) => {
      flowLogMaxList.push(<Option key={child} name="flowLogMax" value={child}>{child}</Option>);
    });

    setMyVersions(arr);
    setClusterLogRetentionOptions(clusterLog);
    setVpcLogRetentionOptions(vpcLog);
    setFlowLogMaxOptions(flowLogMaxList);
    setMyClusterData(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster);
  }, []);

  useEffect(() => {
    if ((isUpdate && updateCluster.prod.versionsAvailable.length > 0 && oneClickCluster.prod.eks !== "") || (isOneClick && oneClickCluster.prod.versionsAvailable.length > 0 && oneClickCluster.prod.eks !== "")) {
      let selectedCluster = isUpdate ? updateCluster : oneClickCluster;
      let result = selectedCluster.prod.versionsAvailable.find((val) => {
        console.log("Value: ", selectedCluster.prod.eks);
        if (val.version === selectedCluster.prod.eks) {
          return val;
        }
      });
      fetchAddons(result);
    }
  }, []);


  let azs = isUpdate ? (updateCluster.availability_zones ? updateCluster.availability_zones : []) : isOneClick ? (oneClickCluster.availability_zones ? oneClickCluster.availability_zones : []) : (currentCluster.availability_zones ? currentCluster.availability_zones : []);
  optionAzs = [];
  azs.map((child, ind) => {
    optionAzs.push(<Option key={child + "-" + ind} name="naz" value={child} disabled={(isUpdate && updateCluster.selectedCluster.cluster_data.availability_zones.includes(child))}>{child}</Option>);
  });

  // const handleChange = value => {
  //   console.log('value.target.name: ', value.target.name);
  //   console.log('value.target.checked: ', value.target.checked);
  //   myClusterData.prod[value.target.name] = value.target.checked;
  //   setData({ ...myClusterData, prod: myClusterData.prod });
  //   setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
  //   setError({
  //     status: false,
  //     msg: ""
  //   });
  // };

  const fetchAddons = (result) => {
    api.get(process.env.REACT_APP_API_URI + `/api/cluster/cluster-addons/${isUpdate ? updateCluster.integration : isOneClick ? oneClickCluster.integration : currentCluster.integration}/${result.id}/`)
      .then((res) => {
        let resData = res.data;
        if (resData.data) {
          myClusterData.prod["addonsData"] = resData;
          if (myClusterData.prod["selectedAddons"].length > 0 && isUpdate) {
            myClusterData.prod["addonsData"].forEach((val) => {
              let ele = myClusterData.prod["selectedAddons"].data.includes(x => x.id === val.addon_id);
              if (!ele) {
                myClusterData.prod["selectedAddons"].push({
                  id: val.addon_id,
                  name: val.display_name,
                  dependsOn: val.depends_on,
                  selected: false,
                  disabled: false,
                  recommended: val.recommended,
                  editable: true,
                  subText: val.info,
                  borderColor: "#f5f5f5",
                  message: ""
                });
              }
            });
          }
        }
        else {
          myClusterData.prod["addonsData"] = { data: {} };
        }
        if (!isUpdate && !isOneClick) {
          setCurrentCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        else if (isUpdate) {
          setUpdateCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        else {
          setOneClickCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        setData({ ...myClusterData, prod: myClusterData.prod });
        setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
        console.log("This is the addons data: ", resData);
      })
      .catch((err) => {
        console.log("Something went wrong in cluster-addons api :", err);
        myClusterData.prod["addonsData"] = { data: {} };
        if (isUpdate) {
          setUpdateCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        else if (isOneClick) {
          setOneClickCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        else {
          setCurrentCluster({ ...myClusterData, prod: myClusterData.prod });
        }
        setData({ ...myClusterData, prod: myClusterData.prod });
        setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
      });
  }

  const handleSelectChange = (value, event) => {
    // console.log('updated myClusterData :', myClusterData);
    let result = isUpdate ? updateCluster["prod"].versionsAvailable.find((val) => {
      if (val.version === value) {
        return val;
      }
    }) : isOneClick ?
      oneClickCluster["prod"].versionsAvailable.find((val) => {
        if (val.version === value) {
          return val;
        }
      }) : currentCluster["prod"].versionsAvailable.find((val) => {
        if (val.version === value) {
          return val;
        }
      })

    fetchAddons(result);

    myClusterData.prod[event.name] = value;
    //myClusterData.prod.selectedAddons = [];
    setData({ ...myClusterData, prod: myClusterData.prod });
    setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
    setError({
      status: false,
      msg: ""
    });
    if (isUpdate) {
      setUpdateCluster({ ...myClusterData, prod: myClusterData.prod });
    }
    else if (isOneClick) {
      setOneClickCluster({ ...myClusterData, prod: myClusterData.prod });
    }
    else {
      setCurrentCluster({ ...myClusterData, prod: myClusterData.prod });
    }
  };

  const handleAdvanceSelectChange = (value, event) => {
    myClusterData.prod[event.name] = value;
    console.log('updated myClusterData :', myClusterData);
    setError({
      status: false,
      msg: ""
    });
    setData({ ...myClusterData, prod: myClusterData.prod });
    setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
  }

  const handleMultiSelectChange = (value, event) => {
    console.log('event :', event);
    console.log('value :', value);
    if (value.length > myClusterData.prerequisiteInfo.available_eip_quota) {
      console.log("Azs Error");
      setError({
        status: true,
        msg: `You can select upto ${myClusterData.prerequisiteInfo.available_eip_quota} availability zones.`
      });
    }
    else {
      console.log('ok');
      setError({
        status: false,
        msg: ""
      })
    }
    myClusterData.prod.naz = value;
    if (isUpdate) {
      setData({ ...updateCluster, prod: myClusterData.prod });
      setMyClusterData({ ...updateCluster, prod: myClusterData.prod });
    }
    else if (isOneClick) {
      setData({ ...oneClickCluster, prod: myClusterData.prod });
      setMyClusterData({ ...oneClickCluster, prod: myClusterData.prod });
    }
    else {
      setData({ ...currentCluster, prod: myClusterData.prod });
      setMyClusterData({ ...currentCluster, prod: myClusterData.prod });
    }
  };

  const handelInputChange = (e) => {
    let val = e.target.value.replace(/\s/g, '');
    if (e.target.name === "name" || e.target.name === "value") {
      // console
      myClusterData.prod.tags[e.target.id][e.target.name] = val;
    }
    else if (["logRetention", "clusterLogRetention", 'flowLogMax'].includes(e.target.name)) {
      //const logRetentionValue = e.target.value.replace(/\D/g, '');
      myClusterData.prod[e.target.name] = e.target.value;
    }
    else {
      myClusterData.prod[e.target.name] = val;
    }

    console.log('updated myClusterData :', myClusterData);
    setError({
      status: false,
      msg: ""
    });
    setData({ ...myClusterData, prod: myClusterData.prod });
    setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
  };

  /*const handelBlurEvent = (e) => {
    if(e.target.name === "vpcCidr"){
      // console
      if(e.target.value.match(/\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])\b/)){
        console.log('True');
        setError({
          status: false,
          msg: ""
        });
      }
      else{
        console.log('False');
        setError({
          status: true,
          msg: "Please provide correct vpc cidr(example: 10.0.0.0/16)"
        });
      }
      myClusterData.prod[e.target.name] = e.target.value;
    }
    // else if(e.target.name === "naz"){
    //   if(myClusterData.prod.naz.length < 2){
    //     console.log('naz length error');
    //   }
    //   else{
    //     console.log('naz length ok');
    //   }
    // }
  }*/

  const filterClusterLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

  const filterVPCLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());


  return (
    <ProdContentHold>
      <CreateTaskHeader
        buttonContent="Cluster"
        data={myClusterData}
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        onDelete={onDelete}
        onEditCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openBasicView={() => {
          setOpenRightDrawer({
            drawerType: "prod",
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openCodeView={() => {
          setOpenRightDrawer({
            drawerType: "prodCodeView",
            drawerProps: {
              data: myClusterData,
              setData: setMyClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
      />

      <Box
        title={myClusterData.integration === 1 ? <div style={{ display: "flex" }}><IntlMessages id="cluster.noofavailablityzones.title" />* {/*isUpdate && <Tooltip placement='bottom' title="You have the option to add an availability zone, but the existing zone selections cannot be modified."><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</div> : <p> <IntlMessages id="cluster.availabilityzones.title" />*</p>}
        subtitle={<IntlMessages id="cluster.noofavailablityzones.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
        style={{ marginTop: "80px" }}
      >
        <ContentHolder>
          <Select
            mode="multiple"
            name="naz"
            id="naz"
            style={{ width: '100%' }}
            placeholder={myClusterData.integration === 1 ? "Select availablity zones" : "Select Zones"}
            defaultValue={myClusterData.prod.naz}
            onChange={handleMultiSelectChange}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            disabled={isUpdate}
            notFoundContent={fetchingClusterPrerequisites ? <Spin size="small" /> : null}
          >
            {
              // fetchingClusterPrerequisites ?
              //   <Option disabled>
              //     <div style={{ width: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
              //       <Spin size="small" />
              //     </div>
              //   </Option>
              //   :
              optionAzs
            }
          </Select>
        </ContentHolder>
        {error.status && <p className="isoHelperText">
          <span>{error.msg}</span>
        </p>}
      </Box>

      {/* <hr style={{ width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px" }} /> */}

      <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.eksversion.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.kubernetesVersion} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.eksversion.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
      >
        <ContentHolder>
          <Select
            defaultValue={myClusterData.prod.eks}
            className="customVersions"
            id="version"
            onChange={handleSelectChange}
            style={{ width: "100%" }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {myVersions}
          </Select>
        </ContentHolder>
      </Box>

      <Box
        title={<div style={{ display: "flex", justifyContent: "space-between" }}>
          <p><IntlMessages id="cluster.vpccidr.title" />*</p>
          <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcCidr} placement={"bottomLeft"} />
        </div>}
        subtitle={<IntlMessages id="cluster.vpccidr.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
      >
        <ContentHolder>
          <Input
            name="vpcCidr"
            value={myClusterData.prod.vpcCidr}
            style={{ ...inputStyle }}
            onChange={handelInputChange}
            //onBlur={handelBlurEvent}
            onKeyDown={(e) => {
              console.log("event.key :", e.key);
              if (e.key === "ArrowRight" && myClusterData.prod.vpcCidr === "") {
                myClusterData.prod[e.target.name] = "10.0.0.0/16";
                setData({ ...myClusterData, prod: myClusterData.prod });
                setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
              }
            }}
            placeholder="10.0.0.0/16"
            maxLength="19"
          />
          {/* {
            isUpdate && <div style={{ display: "flex", marginTop: "20px", cursor: "pointer", width: "fit-content" }}>
              <img src={plusIcon} style={{ width: "15px", height: "15px", marginRight: "10px" }} />
              <p>Add secondary range</p>
            </div>
          } */}
        </ContentHolder >
      </Box >

      <Box
        title={<p>{myClusterData.integration === 1 ? <IntlMessages id="cluster.tags.title" /> : <IntlMessages id="cluster.labels.title" />}</p>}
        titleStyle={innerTitleStyle}
      >
        {myClusterData.prod.tags.map((tag, ind) => (
          <ContentHolder key={"tag-" + ind} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              name="name"
              id={ind}
              value={myClusterData.prod.tags[ind] && myClusterData.prod.tags[ind]['name']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Name" : "Name"}
              style={{ height: "44px", margin: "0 10px" }}
            />
            <Input
              name="value"
              id={ind}
              value={myClusterData.prod.tags[ind] && myClusterData.prod.tags[ind]['value']}
              onChange={handelInputChange}
              placeholder={myClusterData.integration === 1 ? "Tag Value" : "Value"}
              style={{ height: "44px", margin: "0 10px" }}
            />
            {ind === 0 && <Link onClick={(e) => {
              console.log('updated myClusterData :', myClusterData);
              myClusterData.prod.tags.push({
                name: "",
                value: ""
              });
              setData({ ...myClusterData, prod: myClusterData.prod });
              setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
            }}
              style={{ marginRight: "10px" }}
            > <img src={plusIcon} /> </Link>}
            {ind !== 0 && <Link id={ind} onClick={(e) => {
              console.log('Id to be deleted :', e.currentTarget.id);
              myClusterData.prod.tags.splice(e.currentTarget.id, 1)
              setData({ ...myClusterData, prod: myClusterData.prod });
              setMyClusterData({ ...myClusterData, prod: myClusterData.prod });
            }}>
              <img src={deleteIcon} style={{ marginRight: "10px" }} />
            </Link>}
          </ContentHolder>
        ))}
      </Box>

      <Box
        title={<p><IntlMessages id="cluster.vpcflowlogs.title" />*</p>}
        subtitle={<IntlMessages id="cluster.vpcflowlogs.subTitle" />}
        titleStyle={innerTitleStyle}
        subTitleStyle={innerSubtitleStyle}
        style={{ display: "flex", justifyContent: "space-between", pointerEvents: "none", opacity: "0.7" }}
      >
        <Switch style={myClusterData.prod.vpcFlowLogs ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.vpcFlowLogs} onChange={(checked) => {
          setData(data => ({
            ...data,
            prod: {
              ...data.prod,
              vpcFlowLogs: checked
            }
          }))
          setMyClusterData(myClusterData => ({
            ...myClusterData,
            prod: {
              ...myClusterData.prod,
              vpcFlowLogs: checked
            }
          }));
          setError({
            status: false,
            msg: ""
          });
        }} />
      </Box>

      {
        myClusterData.integration === 1 && myClusterData.prod.vpcFlowLogs && <>
          <p style={{ ...innerTitleStyle, fontWeight: "600", margin: "40px 20px 24px 20px", cursor: "pointer" }} onClick={() => {
            setIsAdvanced(isAdvanced => !isAdvanced);
          }}> Advanced Settings <img src={(isAdvanced || isUpdate) ? upIcon : downIcon} /> </p>

          {(isAdvanced || isUpdate) && <div>
            <Alert message="ATTENTION! Opting for Observability could elevate your cloud expense. Please ensure to assess the cost implications carefully." type="error" style={{ margin: "0 20px", color: "#d20a0a", fontSize: "14px", borderRadius: "10px", backgroundColor: "#f1dddd", borderColor: "transparent" }} />
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.kubernetiveslogretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.logRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.kubernetiveslogretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  showSearch
                  defaultValue={myClusterData.prod.clusterLogRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                  }}
                  //filterOption={filterClusterLogOption}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {clusterLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.logretentionperiod.title" />*</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcLogRetention} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.logretentionperiod.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  showSearch
                  defaultValue={myClusterData.prod.logRetention}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                  filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                  onSearch={(value) => {
                    console.log("Enters search", value);
                    setSearchValue(value);
                  }}
                  //filterOption={filterVPCLogOption}
                  //disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {vpcLogRetentionOptions}
                </Select>
              </ContentHolder>
            </Box>
            <Box
              title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                <p><IntlMessages id="cluster.flowlogmaxaggregationinterval.title" />* {/*<Tooltip title="Can be increased but not decreased"><InfoCircleFilled style={{ marginLeft: "10px" }} /></Tooltip>*/}</p>
                <CustomInfoTooltip title={clusterHelpChecks.cluster.vpcMaxAggregation} placement={"bottomLeft"} />
              </div>}
              subtitle={<IntlMessages id="cluster.flowlogmaxaggregationinterval.subTitle" />}
              titleStyle={innerTitleStyle}
              subTitleStyle={innerSubtitleStyle}
            >
              <ContentHolder>
                <Select
                  value={myClusterData.prod.flowLogMax}
                  onChange={handleAdvanceSelectChange}
                  style={{ width: "100%" }}
                  disabled={isUpdate}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {flowLogMaxOptions}
                </Select>
              </ContentHolder>
            </Box>

            <Box
              title={<p><IntlMessages id="cluster.controlPlaneLogging.title" />*</p>}
              subtitle={<IntlMessages id="cluster.controlPlaneLogging.subTitle" />}
              titleStyle={{ ...innerTitleStyle, fontSize: "16px" }}
              subTitleStyle={{ ...innerSubtitleStyle, fontSize: "14px" }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.apiServer.title" /></p>}
                  subtitle={<IntlMessages id="cluster.apiServer.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "40px 0 20px 0" }}
                />
                <Switch style={myClusterData.prod.apiServer ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.apiServer} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    prod: {
                      ...data.prod,
                      apiServer: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    prod: {
                      ...myClusterData.prod,
                      apiServer: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.audit.title" /></p>}
                  subtitle={<IntlMessages id="cluster.audit.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData.prod.audit ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.audit} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    prod: {
                      ...data.prod,
                      audit: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    prod: {
                      ...myClusterData.prod,
                      audit: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.authenticator.title" /></p>}
                  subtitle={<IntlMessages id="cluster.authenticator.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData.prod.authenticator ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.authenticator} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    prod: {
                      ...data.prod,
                      authenticator: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    prod: {
                      ...myClusterData.prod,
                      authenticator: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.controllerManager.title" /></p>}
                  subtitle={<IntlMessages id="cluster.controllerManager.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData.prod.controllerManager ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.controllerManager} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    prod: {
                      ...data.prod,
                      controllerManager: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    prod: {
                      ...myClusterData.prod,
                      controllerManager: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Box
                  title={<p><IntlMessages id="cluster.scheduler.title" /></p>}
                  subtitle={<IntlMessages id="cluster.scheduler.subTitle" />}
                  titleStyle={innerTitleStyle}
                  subTitleStyle={innerSubtitleStyle}
                  style={{ display: "flex", justifyContent: "space-between", padding: "20px 0" }}
                />
                <Switch style={myClusterData.prod.scheduler ? { backgroundColor: "#132f44", opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" } : { opacity: isUpdate && "0.7", pointerEvents: isUpdate && "none" }} checked={myClusterData.prod.scheduler} onChange={(checked) => {
                  setData(data => ({
                    ...data,
                    prod: {
                      ...data.prod,
                      scheduler: checked
                    }
                  }))
                  setMyClusterData(myClusterData => ({
                    ...myClusterData,
                    prod: {
                      ...myClusterData.prod,
                      scheduler: checked
                    }
                  }));
                  setError({
                    status: false,
                    msg: ""
                  });
                }} />
              </div>
            </Box>
          </div>}

        </>
      }

      {/* {isAdvanced && myClusterData.integration === 3 && <div>
        <Box title={<p>Instance Type</p>} titleStyle={innerTitleStyle}/>
        <div style={{display: "flex", padding: "20px", justifyContent: "space-between"}}>
        <Input placeholder='Min' name='instanceTypeMin' value={myClusterData.prod.instanceTypeMin} style={{...inputStyle, width: "48%"}} onChange={handelInputChange}/>
        <Input placeholder='Max' name="instanceTypeMax" value={myClusterData.prod.instanceTypeMax} style={{...inputStyle, width: "48%"}} onChange={handelInputChange}/>
        </div>
      </div>} */}


      {/* <Tooltip title="Please Implements Your Own Attachment Methods">
            <AttachmentWrapper>
              <HeadingWithIcon heading="Attachments" iconSrc={AttachmentIcon} />
              <HeadingWithIcon
                heading="Add an Attachment...."
                iconSrc={AttachmentIcon}
              />
            </AttachmentWrapper>
      </Tooltip> */}

      <Box style={{ height: "150px" }}>
        <Box>
          {/* <ContentHolder style={{
          position: "relative"
        }}>
          <Button type="primary" onClick={() => {
            openAddOns(currentCluster, clusterTabSelected);
            openDrawer();
            }} style={{position: "absolute", right: "0", marginTop: "-20px"}}>
              Save & Next
          </Button>
          {error.status && <p className="isoHelperText">
              <span>{error.msg}</span>
          </p>}
        </ContentHolder> */}
        </Box>
      </Box>
      <CreateTaskFooter buttonContent={"Cluster"} givenButtonFunction1={openAddOns} openDrawer={openDrawer} />
    </ProdContentHold >
  );
};
